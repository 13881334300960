export const hasSubdomainWithV2 = (config, hostname) => {
  // regular expression to match subdomains
  const subdomainRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+/;

  const subdomainMatch = hostname.match(subdomainRegex);

  if (subdomainMatch) {
    const subdomain = subdomainMatch[0];

    if (subdomain.includes('v2')) {
      return hostname;
    }
  }

  return config.surveyName;
};
