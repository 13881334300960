import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { parse } from 'query-string';
import { Formik } from 'formik';
import UpsellTcpa from './upsell/upsellTcpa';
import UpsellPhoneInput from './upsell/upsellPhoneInput';
import UpsellProjectTimeFrame from './upsell/upsellProjectTimeFrame';
import UpsellProjectType from './upsell/upsellProjectType';
import UpsellWindowCount from './upsell/upsellWindowCount';
import UpsellDisclosure from './upsell/upsellDisclosure';
import UpsellHeader from './upsell/UpsellDynamicHeader';
import UpsellLogo from './upsell/upsellLogo';
import sendRequest from './API/sendRequest';
import storage from '../storage';
import { webHookTrack } from '../dynamic/webhookTrack';
import { trustedForm } from '../dynamic/trustedForm';
import { autoScroll } from '../dynamic/autoScroll';
import { jornayaReRun, generateUUID } from './upsell/upsellHelperFunctions';
import { g4EventTracking } from '../dynamic/g4Tracking';

const ModalContainer = styled.div`
  height: ${({ secondary }) => (secondary ? '1px' : '100vh')};
  width: ${({ secondary }) => (secondary ? '1px' : '100vw')};
  position: fixed;
  background: ${({ secondary }) => (secondary ? 'none' : '#fff')};
  margin: 0;
  overflow: auto;
  z-index: ${({ secondary }) => (secondary ? '0' : '999')};
  box-sizing: border-box;
`;

const WindowContainer = styled.section`
  --defaultFontSize: clamp(16px, 2vw, 24px);
  // position: relative;
  padding-top: 20px;
  width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  // overflow: auto;
  @media (max-width: 801px) {
    padding: 10px 20px;
  }
  .solar-label {
    font-weight: 600;
    font-size: 20px;
  }
  .imgContainer {
    max-width: 450px;
  }
  .btn-container {
    margin: 10px auto;
    max-width: 400px;
    width: 100%;
  }
  .submit {
    background: #ee9c3f;
    height: 60px;
    width: 100%;
    color: #fff;
    border: none;
    font-size: 20px;
    font-weight: 500;
    :hover {
      background: #fe7f2d;
      cursor: pointer;
    }
  }
  .imgContainer {
    img {
      margin-bottom: 0;
    }
  }
`;

const StyledForm = styled.form`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  h3 {
    font-size: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-weight: 500;
  }
`;

const WindowUpsell = ({ config }) => {
  const { offerId, surveyType } = config;
  const [newUUID, setNewUUID] = useState('');
  const [formComplete, setFormComplete] = useState(false);
  const [tyOverlay, setTyOverlay] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [params, setParams] = useState({});
  const [leadId, setLeadId] = useState('');
  const [envToggle, setEnvToggle] = useState('');
  const [dynamicText, setDynamicText] = useState('replacing');
  const [timer, setTimer] = useState(15);

  const vwoVariation = storage('session', 'get', 'vwoVariation') || null;
  const typVwoVariation = storage('session', 'get', 'typVwoVariation') || null;

  useEffect(() => {
    trustedForm();
    setNewUUID(generateUUID());
    autoScroll(config);

    if (window.location.search) {
      const query = parse(window.location.search);
      setParams(query);
    }

    try {
      if (surveyType !== 'homeservices') {
        setTimeout(() => {
          jornayaReRun(setLeadId);
        }, 3000);
      }
      const getFormData = localStorage.getItem('formData');

      if (getFormData) {
        const parsedFormData = JSON.parse(getFormData);
        setFormData(parsedFormData);
      }

      const stageKey = JSON.parse(sessionStorage.getItem('stage')) || '';
      const devKey = JSON.parse(sessionStorage.getItem('dev')) || '';
      if (stageKey === 1) {
        setEnvToggle('-stage');
      } else if (devKey === 1) {
        setEnvToggle('-default');
      }
    } catch {
      if (surveyType !== 'homeservices') {
        setTimeout(() => {
          jornayaReRun(setLeadId);
        }, 3000);
      }
      console.log('no local/session data storage');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!hideForm) {
        // fires impression pixel after the question is rendered
        const solarCakeImpressionPixel = new Image();
        // prettier-ignore
        solarCakeImpressionPixel.src = `https://suited45trk.com/i.ashx?E=Dwu8eIrcyDu1Ig35twlssTG7Dj%2FrHPqx&s1=${offerId}&s2=${newUUID}&s3=${vwoVariation}|${typVwoVariation}&s4=${'1976'}&s5=${formData.site_name}`;
        solarCakeImpressionPixel.setAttribute('width', '1');
        solarCakeImpressionPixel.setAttribute('height', '1');
        solarCakeImpressionPixel.setAttribute('border', '0');

        document.body.appendChild(solarCakeImpressionPixel);
      }
    }
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line
  }, [hideForm]);

  useEffect(() => {
    const countDown = setInterval(() => {
      if (timer > 0 && !hideForm) {
        setTimer(timer - 1);
      }
    }, 1000);
    if (timer === 0) {
      clearInterval(countDown);
    }
    const resetTimeout = () => {
      setTimer(15);
    };
    const userEvents = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];
    if (!hideForm) {
      for (let i = 0; i < userEvents.length; i += 1) {
        window.addEventListener(userEvents[i], resetTimeout);
      }
    }
    if (timer === 0) {
      setHideForm(true);

      webHookTrack('upsell', {
        Window_Upsell_Timeout: 'User Timed Out',
        Ty_Page_Shown: `${newUUID} - ${formData.TyPageShown}`,
      });

      g4EventTracking('upsell', {
        Window_Upsell_Timeout: 'User Timed Out',
        Ty_Page_Shown: `${newUUID} - ${formData.TyPageShown}`,
      });
    }

    return () => {
      clearInterval(countDown);
      if (!hideForm) {
        for (let i = 0; i < userEvents.length; i += 1) {
          window.addEventListener(userEvents[i], resetTimeout);
        }
      }
    };

    // eslint-disable-next-line
  }, [hideForm, newUUID, timer]);

  const today = new Date();

  const windowCakePostData = formData
    ? {
        ParentUuid: formData.uuid,
        UUID: newUUID,
        UniversalLeadId: leadId,
        CkmSubId1: offerId,
        CkmSubId2: newUUID,
        CkmSubId3: `${vwoVariation} | ${typVwoVariation}`,
        CkmSubId4: '1976',
        CkmSubId5: formData.site_name,
        CkmOfferId: '2506',
        Product: 'Home Improvement',
        Residential: 'Yes',
        HomeOwner: 'Yes',
        FirstName: formData.first_name,
        LastName: formData.last_name,
        Address: formData.address,
        City: formData.city,
        State: formData.state,
        ZipCode: formData.zipcode,
        Email: formData.email,
        PhoneNumber: formData.phone_primary,
        PhoneSecondary: formData.phone_primary,
        ProjectTimeFrame: 'Timing Flexible',
        ProjectType: 'Replace Unit',
        WindowCount: '6-9',
        ConsentLang:
          '<div class="privacy_rights well" id="srDisclosure"><input id="leadid_tcpa_disclosure" type="hidden" /> <label for="leadid_tcpa_disclosure">By clicking the button and submitting a form, you are providing your electronic signature in which you consent, acknowledge and agree to the following: (a) our <a href="/rmp-tos.html" target="_blank">Terms of Service</a> and <a href="/rmp-privacy.html" target="_blank">Privacy Policy</a>, including our arbitration provision. (b) You are providing express written consent to share your information with RateMarketplace, and up to <a href="/ServicePartners.html" target="_blank">4 home service and solar professionals</a> or authorized third parties calling on our, or their behalf who may contact you about their products and services. (c) You give consent (consent not required as a condition to purchase a good/service) for them/us to contact you through automated means, for marketing purposes at the phone number(s) and at the email address you have provided (e.g. automatic telephone dialing system, text, artificial or pre-recorded messaging) via telephone, mobile device (including SMS and MMS) and/or email, even if your telephone number is currently listed on any state, federal or corporate Do Not Call registry. You may opt-out from SMS or Text messages at any time by replying STOP. Data and Msg rates may apply. As an alternative, you may contact us by email at: <a href="mailto:customercare@ratemarketplace.com">customercare@ratemarketplace.com</a>. You understand that you may revoke this consent at any time.</label></div>',
        IPAddress: formData.user_ip,
        OwnOrRent: 'own',
        UserAgent: formData.user_agent,
        SiteName: 'homewindowsurvey.com',
        EventDate: today.toISOString(),
        CkmAffiliateId: '1976',
        xxTrustedFormToken: formData.xxTrustedFormToken,
        TrustedFormCertUrl: formData.xxTrustedFormCertUrl,
        TcpaType: 'Home Services',
        LoanPurpose: null,
        Project: 'Windows',
        SiteVertical: 'hs_window',
        SubVerticalName: 'Windows',
        TyPageShown: 'GCR USR',
        Vertical: 'home_services',
        CkmCampaignId: '35816',
        CkmKey: '0wWpTZj50xuDgiajccYZA',
        SiteServer: 'sc-frontend-v2',
        MapName: 'FrontEndSignup',
        CakeRequestIdUrl: `https://suited45trk.com/?bbz=HU0wFk6Y0v3jCKdqZ7pUUDG7Dj%2frHPqx&cp=js&s1=${offerId}&s2=${newUUID}&s3=${vwoVariation}|${typVwoVariation}&s4=${'1976'}&s5=${formData.site_name}` // prettier-ignore
      }
    : {};

  const finalSubmit = values => {
    const token = document.getElementById('xxTrustedFormToken_0').value;
    const certUrl = document.getElementById('xxTrustedFormCertUrl_0').value;
    // update main window cake post data with five fields from form
    windowCakePostData.ProjectTimeFrame = values.ProjectTimeFrame;
    windowCakePostData.Windows = values.Windows;
    windowCakePostData.PageId = values.___pageid___;
    windowCakePostData.xxTrustedFormToken = token;
    windowCakePostData.TrustedFormCertUrl = certUrl;

    // update these values for the upsell phone test
    if (surveyType !== 'homeservices') {
      windowCakePostData.PhoneNumber = values.PhoneNumber.replace(/\D+/g, '');
      windowCakePostData.PhoneSecondary = values.PhoneNumber.replace(
        /\D+/g,
        ''
      );
    }

    setTyOverlay(true);
    setFormComplete(true);

    const successFunction = data => {
      if (data) {
        console.log(data);
      }
    };
    setTimeout(() => {
      setHideForm(true);
    }, 2000);

    const postToCakeAPI = `https://external${envToggle}.printfinger.tech:7113/api/BuyerPreQualification`;

    const submitData = {
      BuyerName: 'Home Services',
      MapName: 'FrontEndSignup',
      Uuid: newUUID,
      Payload: windowCakePostData,
    };

    // send information through API
    sendRequest(submitData, postToCakeAPI, successFunction);
  };

  const formSubmit = (values, action) => {
    webHookTrack('upsell', {
      Window_Upsell_Submit: true,
      Window_Upsell_UUID: newUUID,
      Ty_Page_Shown: `${newUUID} - ${formData.TyPageShown}`,
    });

    g4EventTracking('upsell', {
      Window_Upsell_Submit: true,
      Window_Upsell_UUID: newUUID,
      Ty_Page_Shown: `${newUUID} - ${formData.TyPageShown}`,
    });

    action.setSubmitting(false);
    return finalSubmit(values);
  };

  const handleSolarYesNo = value => {
    if (value === 0) {
      webHookTrack('upsell', {
        No_Thanks: true,
        Ty_Page_Shown: `${newUUID} - ${formData.TyPageShown}`,
      });

      g4EventTracking('upsell', {
        No_Thanks: true,
        Ty_Page_Shown: `${newUUID} - ${formData.TyPageShown}`,
      });

      setHideForm(true);
    }
  };
  return (
    <ModalContainer id="upsellModal" secondary={hideForm}>
      {!hideForm && (
        <WindowContainer>
          <UpsellLogo upsellType="window" />
          <div id="windowHeader">
            <UpsellHeader
              formData={formData}
              handleSolarYesNo={handleSolarYesNo}
            />

            {tyOverlay && (
              <div data-testid="solar-form-submission">
                <h2>Thank you!</h2>
                <p>Someone will contact you soon regarding window options!</p>
              </div>
            )}
            {!formComplete && (
              <Formik
                enableReinitialize
                initialValues={{
                  ProjectTimeFrame: 'Timing Flexible',
                  Windows: '6-9',
                  ProjectType: 'Replace Unit',
                  universal_leadid: '',
                  PhoneNumber: '',
                  ___pageid___: '',
                }}
                onSubmit={formSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({
                  handleSubmit,
                  values,
                  errors,
                  setFieldValue,
                  setErrors,
                }) => (
                  <StyledForm id="windowUpsell" onSubmit={handleSubmit}>
                    <div className="solar-questions">
                      <UpsellProjectType
                        setDynamicText={setDynamicText}
                        config={config}
                      />
                    </div>
                    <div className="solar-questions">
                      <UpsellWindowCount
                        dynamicText={dynamicText}
                        config={config}
                      />
                    </div>
                    <div className="solar-questions">
                      <UpsellProjectTimeFrame config={config} />
                    </div>
                    <div className="solar-questions">
                      <UpsellPhoneInput
                        config={config}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                      />
                    </div>
                    <div className="btn-container">
                      <button
                        type="submit"
                        className="submit"
                        id="solar-check"
                        data-testid="solar-form-submit"
                      >
                        MATCH ME & SHOW MY RESULTS!
                      </button>
                    </div>
                    {surveyType !== 'homeservices' && (
                      <UpsellTcpa upsellType="homeservices" />
                    )}
                    {surveyType !== 'homeservices' && (
                      <UpsellDisclosure config={config} params={params} />
                    )}
                  </StyledForm>
                )}
              </Formik>
            )}
          </div>
        </WindowContainer>
      )}
    </ModalContainer>
  );
};

export default WindowUpsell;
