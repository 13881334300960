import { parse } from 'query-string';
import { webHookTrack } from './webhookTrack';
import storage from '../storage';
import { g4EventTracking } from './g4Tracking';
// let deviceType;
// if (/iPad/.test(navigator.userAgent)) {
//   deviceType = 't';
// } else if (
//   /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
//     navigator.userAgent
//   )
// ) {
//   deviceType = 'm';
// } else {
//   deviceType = 'd';
// }
/**
 * ga event tracking
 * @param  {string} name
 * @param  {string} value
 */

// ga step tracking

const firePageLoadEvents = (
  params,
  parsed,
  version = '',
  experiment,
  config
) => {
  return new Promise(resolve => {
    const date = new Date();
    const gaInterval = setInterval(() => {
      const { a, s1, s2, s3, s4, s5, req_id, wpuuid } = parsed;

      const {
        uuid,
        offer_id,
        userCity,
        userState,
        userZip,
        user_ip,
        zipcode,
        state,
        city,
      } = params;

      const gaLoadEvents = {
        affiliateId: a || '52',
        s1: s1 || '',
        s2: s2 || '',
        s3: s3 || '',
        s4: s4 || '',
        s5: s5 || '',
        uuid: uuid || '',
        ckmRequestId:
          req_id !== undefined ? req_id : window.ckm_request_id || '', // Need reg_id to consolidate against click reports for A/B testing in Optimize.
      };
      if (typeof window !== 'undefined' && window?.gtag) {
        Object.entries(gaLoadEvents).forEach(([key, value]) => {
          if (value) {
            webHookTrack('individual_load_events', { [key]: value });

            g4EventTracking('individual_load_events', { [key]: value });
          }
        });
        const pageLoadJson = {
          uuid,
          CkmRequestId: gaLoadEvents.ckmRequestId,
          testVariation: version,
          CkmOfferid: offer_id,
          ckmAffiliateId: a || '52',
          TYPTest: false,
          ExperimentId: experiment,
          city: userCity || city || '',
          state: userState || state || '',
          zipcode: userZip || zipcode || '',
          UserAgent: navigator.userAgent,
          site_url: config.scData.siteName || window?.location?.host,
          ip_address: user_ip,
          FORM_TEST: version ? 'true' : false,
          frame: '/',
          ScreenDimHeight: window?.innerHeight || '',
          ScreenDimWidth: window?.innerWidth || '',
          CKMSUBID1: s1 || '',
          timeStamp: date.getTime(),
          wp_uuid: wpuuid || '',
        };

        webHookTrack('page_view', pageLoadJson);

        g4EventTracking('page_view', pageLoadJson);

        clearInterval(gaInterval);
        return resolve();
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(gaInterval);
      return resolve();
    }, 10000);
  });
};

// const getOptimizeVersion = {
//   goLandingPage(params, parsed, path, page, config) {
//     const { version, typversion } = parsed;
//     const { uuid } = params;
//     return new Promise((resolve, reject) => {
//       const paramValue = path ? typversion : version;
//       if (paramValue && !page) {
//         const goData = setInterval(() => {
//           if (typeof window !== 'undefined' && window.google_optimize) {
//             let variation = '';
//             switch (paramValue) {
//               case '0':
//                 variation = 'test0';
//                 console.log(`Version ${paramValue} loaded`);

//                 webHookTrack('individual_load_events', { test0: uuid });

//                 g4EventTracking('individual_load_events', { test0: uuid });

//                 break;
//               case '1':
//                 variation = 'test1';
//                 console.log(`Version ${paramValue} loaded`);

//                 webHookTrack('individual_load_events', { test1: uuid });

//                 g4EventTracking('individual_load_events', { test1: uuid });

//                 break;
//               case '2':
//                 variation = 'test2';
//                 console.log(`Version ${paramValue} loaded`);

//                 webHookTrack('individual_load_events', { test2: uuid });

//                 g4EventTracking('individual_load_events', { test2: uuid });

//                 break;
//               case '3':
//                 variation = 'test3';
//                 console.log(`Version ${paramValue} loaded`);

//                 webHookTrack('individual_load_events', { test3: uuid });

//                 g4EventTracking('individual_load_events', { test3: uuid });

//                 break;
//               case '4':
//                 variation = 'test4';
//                 console.log(`Version ${paramValue} loaded`);

//                 webHookTrack('individual_load_events', { test4: uuid });

//                 g4EventTracking('individual_load_events', { test4: uuid });

//                 break;
//               case '5':
//                 variation = 'test5';
//                 console.log(`Version ${paramValue} loaded`);

//                 webHookTrack('individual_load_events', { test5: uuid });

//                 g4EventTracking('individual_load_events', { test5: uuid });

//                 break;
//               case '6':
//                 variation = 'test6';
//                 console.log(`Version ${paramValue} loaded`);

//                 webHookTrack('individual_load_events', { test6: uuid });

//                 g4EventTracking('individual_load_events', { test6: uuid });

//                 break;
//               default:
//                 variation = 'MvtTest';
//                 console.log(`Version ${paramValue} loaded`);

//                 webHookTrack('individual_load_events', { MvtTest: uuid });

//                 g4EventTracking('individual_load_events', { MvtTest: uuid });
//             }
//             clearInterval(goData);
//             resolve(paramValue);
//             setTimeout(() => {
//               storage(
//                 'session',
//                 'set',
//                 `${path ? 'typVwoVariation' : 'vwoVariation'}`,
//                 paramValue
//               );
//             }, 3500);
//           }
//         }, 100);
//         setTimeout(() => {
//           resolve(paramValue);
//           clearInterval(goData);
//         }, 5000);
//       } else {
//         const goData = setInterval(() => {
//           if (typeof window !== 'undefined' && window.gtag) {
//             const checkExperimentValue = (value, name) => {
//               if (value) {
//                 resolve(value);
//               }
//             };
//             window.gtag('event', 'optimize.callback', {
//               callback: checkExperimentValue,
//             });
//           }
//           if (typeof window !== 'undefined' && window.gaData) {
//             const { gaData } = window;
//             if (Object.keys(gaData).length >= 1) {
//               let returnObj;
//               Object.entries(gaData).forEach(([key, value]) => {
//                 if (key.includes('UA')) {
//                   returnObj = value;
//                 }
//               });
//               if (returnObj && returnObj.experiments) {
//                 const testValueArray = Object.values(returnObj.experiments);
//                 const lastIndex = testValueArray.length - 1;
//                 let testValue;
//                 if (testValueArray.length <= 1) {
//                   // eslint-disable-next-line prefer-destructuring
//                   testValue = testValueArray[0];
//                 } else {
//                   testValue = testValueArray[lastIndex];
//                 }
//                 if (testValue) {
//                   if (testValue.includes('-')) {
//                     testValue = testValue.split('-').join('');
//                   }
//                   let variation;
//                   switch (testValue) {
//                     case '0':
//                       variation = 'test0';
//                       break;
//                     case '1':
//                       variation = 'test1';
//                       break;
//                     case '2':
//                       variation = 'test2';
//                       break;
//                     case '3':
//                       variation = 'test3';
//                       break;
//                     case '4':
//                       variation = 'test4';
//                       break;
//                     case '5':
//                       variation = 'test5';
//                       break;
//                     case '6':
//                       variation = 'test6';
//                       break;
//                     default:
//                       variation = 'MvtTest';
//                   }
//                   storage(
//                     'session',
//                     'set',
//                     `${path ? 'typVwoVariation' : 'vwoVariation'}`,
//                     testValue
//                   );
//                   clearInterval(goData);
//                   resolve(testValue);
//                 }
//               }
//             }
//           }
//         }, 100);
//         setTimeout(() => {
//           resolve('');
//           clearInterval(goData);
//         }, 3000);
//       }
//     });
//   },
// };

// const checkExperiments = config => {
//   return new Promise((resolve, reject) => {
//     const goData = setInterval(() => {
//       if (typeof window !== 'undefined' && window.gtag) {
//         const checkExperimentName = (value, name) => {
//           if (name) {
//             resolve(name);
//           }
//         };
//         window.gtag('event', 'optimize.callback', {
//           callback: checkExperimentName,
//         });
//       }
//       if (typeof window !== 'undefined' && window.gaData) {
//         const { gaData } = window;
//         if (Object.keys(gaData).length >= 1) {
//           let returnObj;
//           Object.entries(gaData).forEach(([key, value]) => {
//             if (key.includes('UA')) {
//               returnObj = value;
//             }
//           });
//           if (returnObj && returnObj.experiments) {
//             const experimentsArray = Object.keys(returnObj.experiments);

//             const lastIndex = experimentsArray.length - 1;

//             if (experimentsArray) {
//               if (experimentsArray.length <= 1) {
//                 // eslint-disable-next-line prefer-destructuring
//                 resolve(experimentsArray[0]);
//                 storage('session', 'set', 'ExperimentId', experimentsArray[0]);
//                 clearInterval(goData);
//               } else {
//                 resolve(experimentsArray[lastIndex]);
//                 storage(
//                   'session',
//                   'set',
//                   'ExperimentId',
//                   experimentsArray[lastIndex]
//                 );
//                 clearInterval(goData);
//               }
//             } else {
//               resolve('');
//             }
//           }
//         }
//       }
//     }, 100);
//     setTimeout(() => {
//       resolve('');
//       clearInterval(goData);
//     }, 3000);
//   });
// };

const firePageAnswer = (uuid, name, value, config, frame) => {
  const date = new Date();
  const PageViewData = {
    uuid: uuid || storage('session', 'get', 'uuid'),
    question: name,
    answer: value,
    timeStamp: date.getTime(),
    frame,
    // [name]: value,
  };

  webHookTrack('page_answer', PageViewData);

  g4EventTracking('page_answer', PageViewData);
};

const firePageViewEvent = (uuid, frameId, offer, site, config) => {
  const date = new Date();
  const PageViewData = {
    uuid: uuid || storage('session', 'get', 'uuid'),
    frame: frameId,
    timeStamp: date.getTime(),
  };

  webHookTrack('page_view', PageViewData);

  g4EventTracking('page_view', PageViewData);
};

export { firePageLoadEvents, firePageAnswer, firePageViewEvent };
