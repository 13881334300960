import React, { useEffect, useState, Fragment } from 'react';
import { parse } from 'query-string';
import InnerHTML from 'dangerously-set-html-content';
import ThankyouSeo from './ThankyouSeo';
import storage from '../../utils/storage';
import GlobalStyle from '../../utils/GlobalStyle';
import getCakeValues from '../../utils/dynamic/getCakeValues';
import { useLayoutQueries } from '../../utils/queries';
import { returnedConnectedStreamString } from '../ConnectedStreams';
import SolarTYForm from '../../utils/forms/solarUpsellTY';
import WindowUpsell from '../../utils/forms/windowUpsell';
import HomeInsuranceUpsell from '../../utils/forms/homeInsuranceUpsell';
import gtmLoad from '../../utils/dynamic/gtmLoad';
import { g4EventTracking } from '../../utils/dynamic/g4Tracking';
import { loadScript } from '../../utils/loadScript';
import sendClickData from '../../utils/forms/API/sendClickData';
import { hasSubdomainWithV2 } from '../../utils/stagingSiteCheck';

/**
 *
 * @param {*} Component - The component that is wrapped.
 * @param {*} googleFontLink - Take a look at how this is uploaded in evu-thankyou
 */

// thankyou routes that need the jornaya script
const jornayaList = [
  'sl-thankyou',
  'tel-thankyou',
  'usl-thankyou',
  'shi-thankyou',
  'ty',
];

const userIP = async () => {
  try {
    const address = await fetch('https://api.ipify.org?format=json');
    const result = await address.json();
    return result.ip;
  } catch (err) {
    return null;
  }
};

const withThankyouWrapper = (Component, googleFontLink = '') => {
  // fake vwo functions to allow vwo to fail on this silently w/o blowing up Sentry
  try {
    window.alterConfig = () => {
      // fail silently
    };
    window.alterQuestions = () => {
      // fail silently
    };
    window.addQuestion = () => {
      // fail silently
    };
    window.removeQuestion = () => {
      // fail silently
    };
    window.changeFromSlider = () => {
      // fail silently
    };
  } catch (error) {
    // fail silently
  }
  // jornaya postback to get token set to storage
  if (typeof window !== `undefined`) {
    window.jornayaLeadId = token => {
      console.log('hit jornaya-typ', token);
      sessionStorage.setItem('jornaya_lead', token);
    };
    window.connectedStreamsModal = moduleID => {
      returnedConnectedStreamString(moduleID);
    };
  }
  const ThankyouWrapper = props => {
    const { path, location } = props;

    const [hitsJornaya, setHitsJornaya] = useState(false);
    const [solarInterest, setSolarInterest] = useState(false);
    const [windowInterest, setWindowInterest] = useState(false);
    const [updatedConfig, setUpdatedConfig] = useState({});
    const [html, setHtml] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [LoanPurpose, setLoanPurpose] = useState('');
    const [TypTestVersion, setTypTestVersion] = useState(null);
    const { config, alternateConfig1, alternateConfig2 } = useLayoutQueries();

    // if (typeof window !== 'undefined') {
    //   window.jtkTest = jtkTestConfig => {
    //     setUpdatedConfig({
    //       ...config,
    //       ...jtkTestConfig.config,
    //     });
    //   };
    // }

    useEffect(() => {
      const hasSolarInterest =
        storage('session', 'get', 'SolarInterest') || false; // temp for VWO test
      setSolarInterest(hasSolarInterest);
      const hasWindowInterest =
        storage('session', 'get', 'WindowInterest') || false; // temp for VWO test
      setWindowInterest(hasWindowInterest);

      setTimeout(() => {
        setLoaded(true);
      }, 700);

      // Fire Jornaya snapshots
      if (typeof window.LeadiD !== 'undefined') {
        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          window?.LeadiD?.snap();
        }, 800);
        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          window?.LeadiD?.snap();
        }, 3000);
        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          window?.LeadiD?.snap();
        }, 6000);
      }
      g4EventTracking('page_view', {
        page_location: `${window.location.origin}/${path}`,
      });
    }, [path]);

    useEffect(() => {
      const query = parse(window.location.search);
      try {
        if (query.version) {
          switch (query.version) {
            case '1':
              setUpdatedConfig(alternateConfig1);

              break;
            case '2':
              setUpdatedConfig(alternateConfig2);
              break;
            default:
              setUpdatedConfig(config);
          }
        } else {
          setUpdatedConfig(config);
        }
        if (query.loan_purpose) {
          setLoanPurpose(query.loan_purpose);
        }
      } catch (error) {
        setUpdatedConfig(config);
      }
    }, []); // eslint-disable-line

    useEffect(() => {
      const query = parse(window.location.search);
      try {
        // eslint-disable-next-line
        const customConfig = require(`../../../data/sites/${process.env.GATSBY_SITE_TYPE}/${process.env.GATSBY_SITE_NAME}/custom.js`);
        if (customConfig?.default) {
          customConfig.default(
            updatedConfig,
            () => {},
            query,
            () => {}
          );
        }

        if (query.loan_purpose) setLoanPurpose(query.loan_purpose);
      } catch {
        // Intentionally left blank, try block is checking if 'custom.js' file exists
      }

      if (query.dty === '1') {
        updatedConfig.isSCLabs = true;
      }

      /**
       * loads GTM
       * @param  {object} updatedConfig
       * @param  {bool} isTYPGTM
       */

      const cakeValues = getCakeValues();
      setHtml(cakeValues);

      // eslint-disable-next-line react/destructuring-assignment
      if (jornayaList.some(item => props.path.includes(item))) {
        setHitsJornaya(true);
      }

      // fires back button page GA Event
      // eslint-disable-next-line react/prop-types
      if (path === '/back/') {
        setTimeout(() => {
          try {
            if (window.ga) {
              window.ga
                .getAll()[0]
                .send(
                  'event',
                  'backbutton',
                  window.location.host,
                  'backbutton',
                  1
                );
            }
          } catch (error) {
            console.log(error);
            // do nothing
          }
        }, 500);
      }
    }, [updatedConfig]); // eslint-disable-line

    useEffect(() => {
      // const query = parse(window.location.search);
      // const tempParams = async () => {
      //   return {
      //     offer_id: parseInt(query.o) || updatedConfig.offerId,
      //     uuid: storage('session', 'get', 'uuid') || '',
      //     envToggle: query.stage === '1' ? '-stage' : '',
      //     user_ip: (await userIP()) || '',
      //   };
      // };
      if (typeof window !== 'undefined' && (!window.jtknet || !window.lincx)) {
        (async () => {
          if (Object.keys(updatedConfig).length > 1) {
            gtmLoad(updatedConfig, true);
          }
        })();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedConfig]);

    useEffect(() => {
      const splitBuyerPayload = storage('session', 'get', 'splitBuyerPayload');
      const formData = storage('session', 'get', 'formData');

      const parsedSpiltBuyerPayload = splitBuyerPayload
        ? JSON.parse(splitBuyerPayload)
        : {};

      const parsedFormData = formData ? JSON.parse(formData) : {};
      const tyPageContent = parsedSpiltBuyerPayload?.tyPageContent || 'ty';
      // eslint-disable-next-line prefer-destructuring
      const hostname = window.location.hostname;
      const params = window.location.search;
      const parsedParams = parse(params);

      if (typeof window?.jtksplits?.init !== 'function') {
        loadScript('https://offers.printfingertech.net/js/jtksplits.js');
      }

      if (!window.jtknet) {
        // loadScript('//insurance.mediaalpha.com/js/serve.js');
        loadScript('https://offers.printfingertech.net/js/jtknet.js');
      }

      const jtkSplitsInterval = setInterval(async () => {
        if (typeof window?.jtksplits?.init === 'function') {
          /**
           * updates main config from dynamicSites config
           * @params1  {string} surveyName
           * @params2  {Bool} altStyle
           * @params3  {Bool} isTypTest
           * @params4  {string} route
           */
          const result = await window?.jtksplits?.init(
            hasSubdomainWithV2(config, hostname),
            false,
            true,
            tyPageContent
          );

          if (result?.result?.challenger) {
            storage(
              'session',
              'set',
              'vwoTypVariation',
              result.result.challenger
            );

            setTypTestVersion(result.result.challenger);

            sendClickData(
              config,
              parsedFormData,
              parsedParams,
              'ty',
              () => {},
              true
            );
          }

          setTimeout(() => {
            clearInterval(jtkSplitsInterval);
          }, 0);
        }
      }, 500);
      setTimeout(() => {
        clearInterval(jtkSplitsInterval);
      }, 3000);

      return () => clearInterval(jtkSplitsInterval);
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Fragment>
        <ThankyouSeo
          jornayaScript={hitsJornaya}
          googleFontLink={googleFontLink}
          path={path}
          config={updatedConfig}
        />
        <GlobalStyle />
        {updatedConfig?.doSolarUpsell &&
          solarInterest &&
          path.includes('thankyou') && <SolarTYForm config={updatedConfig} />}
        {updatedConfig?.doWindowUpsell &&
          windowInterest &&
          path.includes('thankyou') && <WindowUpsell config={updatedConfig} />}
        {updatedConfig?.homeInsuranceUpsellTest &&
          !solarInterest &&
          path.includes('thankyou') &&
          LoanPurpose === 'Refinance' && (
            <HomeInsuranceUpsell config={updatedConfig} />
          )}
        {loaded && (
          <Component
            {...props}
            config={updatedConfig}
            TypTestVersion={TypTestVersion}
          />
        )}
        <InnerHTML
          id="script-container"
          html={html}
          style={{ display: 'none' }}
        />
      </Fragment>
    );
  };

  return ThankyouWrapper;
};

export default withThankyouWrapper;
