import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { parse } from 'query-string';
import { Formik } from 'formik';
import sendRequest from './API/sendRequest';
import { solarZipCheck } from './API/requests';
import { trustedForm } from '../dynamic/trustedForm';
import storage from '../storage';
import UpsellDisclosure from './upsell/upsellDisclosure';
import UpsellTcpa from './upsell/upsellTcpa';
import UpsellPhoneInput from './upsell/upsellPhoneInput';
import UpsellCreditInput from './upsell/upsellCreditInput';
import UpsellElectricProvider from './upsell/upsellElectricProvider';
import UpsellProviderButtons from './upsell/upsellProviderButtons';
import UpsellShadeInput from './upsell/upsellShadeInput';
import UpsellHeader from './upsell/upsellHeader';
import UpsellLogo from './upsell/upsellLogo';
import {
  jornayaReRun,
  isRequired,
  handleCredit,
  submitText,
  uuidPost,
  campaignKey,
  campaignId,
  affiliateId,
  generateUUID,
  impressionPixelFire,
  clickPixelFire,
} from './upsell/upsellHelperFunctions';
import { webHookTrack } from '../dynamic/webhookTrack';
import { g4EventTracking } from '../dynamic/g4Tracking';

const Solar = styled.section`
  --defaultFontSize: clamp(16px, 2vw, 24px);
  position: relative;
  padding-top: 20px;
  width: 100vw;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: scroll;
  @media (max-width: 801px) {
    position: absolute;
    height: 200vh;
  }
  input,
  select {
    -webkit-appearance: none;
    background: #fff;
    border: 2px solid #dedede;
    border-radius: 5px;
    font-size: 16px;
    height: 50px;
    outline: none;
    padding: 0 20px;
  }
  .imgContainer {
    max-width: 450px;
    img {
      width: 100%;
      margin-bottom: 0;
    }
    @media (max-width: 801px) {
      position: absolute;
      top: 0;
    }
  }
  #solarInit {
    @media (max-width: 801px) {
      position: absolute;
      top: 70px;
    }
  }
  select {
    background: url('https://s3.amazonaws.com/sc-dba.com/qkn-ty/images/dropdown-carat.png')
      97% 50%/20px no-repeat;
    // max-width: 400px;
    width: 100%;
  }
  p,
  h3 {
    text-align: left;
  }
  input {
    max-width: 360px;
    width: calc(60% - 44px);
  }

  input.border-error,
  select.border-error {
    border: 2px solid #bb0000;
  }

  .congrats {
    font-size: var(--defaultFontSize);
    font-weight: bold;
  }

  .interestText {
    font-size: var(--defaultFontSize);
  }

  .label {
    font-weight: 700;
    margin-right: 20px;
    text-align: right;
    width: calc(40% - 20px);
  }
  .offer {
    text-align: center;
    margin: 0 0 10px;
  }
  #solarForm {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    h3 {
      font-size: 20px;
      margin-bottom: 5px;
      margin-top: 5px;
      font-weight: 500;
    }
  }
  .solar-question {
    font-size: 18px;
    text-align: center;
  }
  .solar-questions {
    font-size: 16px;
    margin: 10px auto 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .solar-label {
      width: 100%;
      color: #56aee1;
      font-weight: 700;
      span {
        font-weight: 400;
      }
    }
  }
  #solar-check {
    color: #ffffff;
    font-size: 18px;
    margin: 0 0 10px;
    background: #08d54e;
    border-radius: 0;
    border: none;
  }
  .alert-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px 0;
    margin: 10px auto;
    border-top: 2px solid #bb0000;
    border-bottom: 2px solid #bb0000;
    h3 {
      line-height: 1;
      margin: 0;
      padding: 0;
      font-weight: 500;
    }
    .red {
      color: #bb0000;
    }
  }
  .alert-icon {
    height: auto;
    width: 20px;
    margin: 0;
  }
  .eligibility {
    width: 100%;
    border-radius: 5px;
    margin: 0 auto 10px;
    min-height: 140px;
    padding: 5px 20px;
    ul {
      display: block;
      padding: 0;
      li {
        text-align: left;
        font-size: 15px;
        margin-bottom: 5px;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
  .buttonContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
  }
  .btn-container {
    margin: 10px auto;
    max-width: 400px;
    width: 100%;
  }
  button {
    outline: none;
    transition: background 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      background-color: #bbb;
      opacity: 0.5;
      :hover {
        cursor: not-allowed;
      }
    }
    &.submit {
      background: #f76201;
      height: 60px;
      width: 100%;
      :hover {
        background: #fe7f2d;
      }
    }
    &.cta {
      background: #223444;
      color: #ffffff;
      font-size: 18px;
      margin: 0 0 10px;
      :hover {
        background: #334e66;
      }
    }
    &.onlyRoofing {
      background: transparent;
      border: none;
      color: #787676;
      font-size: calc(var(--defaultFontSize) - 4px);
      margin: 0;
      height: auto;
      width: auto;
      border-bottom: 1px solid #787676;
      border-radius: 0;
      p {
        margin-bottom: 0;
      }
    }
  }
  .question {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
    flex-direction: row;
    .choiceContainer {
      > input:checked + label {
        border: 3px solid #02d64d;
      }
      width: 24%;
      height: 150px;
      @media (max-width: 801px) {
        width: 48%;
        margin-top: 10px;
      }
    }
    #phone-input {
      width: 100%;
      max-width: 100%;
    }
    @media (max-width: 801px) {
      padding: 0 20px;
    }
  }
  .tcpa-container {
    p {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;
    }
  }

  @media (max-width: 801px) {
    .solar {
      .solar-questions {
        margin: 0 auto;
        .solar-label {
          width: 100%;
        }
        select {
          width: 100%;
        }
      }
    }
  }
`;

const SolarTyOverlay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
  section h2 {
    margin: -20px 0 10px 0;
  }
  p {
    font-size: 16px;
    text-align: center;
  }
`;

const ModalContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  overflow: scroll;
  // margin: -20px 0 0;
  display: ${({ secondary }) => (secondary ? 'none' : 'block')};
  z-index: ${({ secondary }) => (secondary ? '0' : '999')};
  @media (max-width: 801px) {
    position: absolute;
  }
`;

const SolarTYForm = ({ config }) => {
  const { offerId, surveyType } = config;
  const [lsData, setLsData] = useState({});
  const [noLocalStorage, setNoLocalStorage] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [tyOverlay, setTyOverlay] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const [electricCompany, setElectricCompany] = useState([]);
  const [envToggle, setEnvToggle] = useState('');
  const [zipCheck, setZipCheck] = useState(false);
  const [leadId, setLeadId] = useState('');
  const [timer, setTimer] = useState(15);
  const [newUUID, setNewUUID] = useState('');
  const [formattedPhoneNumber, setPhoneNumber] = useState('');
  const [params, setParams] = useState({});
  // const [didErr, setDidErr] = useState(false);

  const vwoVariation = storage('session', 'get', 'vwoVariation') || null;
  const typVwoVariation = storage('session', 'get', 'typVwoVariation') || null;

  useEffect(() => {
    if (window.location.search) {
      const query = parse(window.location.search);
      setParams(query);
    }
    const body = document.querySelector('body');

    if (body) {
      body.style.height = '100vh';
      body.style.overflow = 'hidden';
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hideForm) {
      const body = document.querySelector('body');

      if (body) {
        body.style.height = 'initial';
        body.style.overflow = '';
      }
    }
  }, [hideForm]);

  useEffect(() => {
    trustedForm();
    setNewUUID(generateUUID());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      webHookTrack('upsell', { ty_page: lsData.TyPageShown });

      g4EventTracking('ileads', 'upsell', { ty_page: lsData.TyPageShown });
    }, 1000);
  }, [lsData]);

  useEffect(() => {
    const countDown = setInterval(() => {
      if (timer > 0 && !hideForm) {
        setTimer(timer - 1);
      }
    }, 1000);
    if (timer === 0) {
      clearInterval(countDown);
    }
    const resetTimeout = () => {
      setTimer(15);
    };
    const userEvents = ['load', 'mousemove', 'click', 'scroll', 'keypress'];
    if (!hideForm) {
      for (let i = 0; i < userEvents.length; i += 1) {
        window.addEventListener(userEvents[i], resetTimeout);
      }
    }
    if (timer === 0) {
      setHideForm(true);

      webHookTrack('upsell', {
        Upsell_Timeout: 'User Timed Out',
        Ty_Page_Shown: `${newUUID} - ${lsData.TyPageShown}`,
      });

      g4EventTracking('upsell', {
        Upsell_Timeout: 'User Timed Out',
        Ty_Page_Shown: `${newUUID} - ${lsData.TyPageShown}`,
      });
    }

    return () => {
      clearInterval(countDown);
      if (!hideForm) {
        for (let i = 0; i < userEvents.length; i += 1) {
          window.addEventListener(userEvents[i], resetTimeout);
        }
      }
    };

    // eslint-disable-next-line
  }, [hideForm, newUUID, timer]);

  useEffect(() => {
    try {
      if (surveyType !== 'homeservices') {
        setTimeout(() => {
          jornayaReRun(setLeadId);
        }, 3000);
      }
      const getFormData = localStorage.getItem('formData');

      if (getFormData) {
        const parsedFormData = JSON.parse(getFormData);
        setLsData(parsedFormData);
      }

      const stageKey = JSON.parse(sessionStorage.getItem('stage')) || '';
      const devKey = JSON.parse(sessionStorage.getItem('dev')) || '';
      if (stageKey === 1) {
        setEnvToggle('-stage');
      } else if (devKey === 1) {
        setEnvToggle('-default');
      }
    } catch {
      if (surveyType !== 'homeservices') {
        setTimeout(() => {
          jornayaReRun(setLeadId);
        }, 3000);
      }
      console.log('no local/session data storage');
    }
    // eslint-disable-next-line
  }, []);

  // checks if the zipcode is in the database or not.
  useEffect(() => {
    const zipCallback = res => {
      // The callback from the zip api call. Will contain the response.
      if (
        res &&
        res.Payload !== null &&
        res.Payload !== undefined &&
        res.Payload.ZipExists === true
      ) {
        setZipCheck(true);
      }
    };
    if (lsData && lsData.zipcode) {
      solarZipCheck(lsData.zipcode, zipCallback, envToggle);
    }
  }, [envToggle, lsData]);

  // this effect fires the solar form impression pixel if the three conditions are met
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!hideForm && !noLocalStorage && zipCheck) {
        // fires impression pixel after the question is rendered
        const solarCakeImpressionPixel = new Image();
        // prettier-ignore
        solarCakeImpressionPixel.src = impressionPixelFire(surveyType, lsData, newUUID, vwoVariation, typVwoVariation);
        solarCakeImpressionPixel.setAttribute('width', '1');
        solarCakeImpressionPixel.setAttribute('height', '1');
        solarCakeImpressionPixel.setAttribute('border', '0');

        document.body.appendChild(solarCakeImpressionPixel);
      }
    }
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line
  }, [hideForm, noLocalStorage, zipCheck, lsData]);

  useEffect(() => {
    if (lsData.phone_primary) {
      const displayNumber = `(${lsData.phone_primary.slice(
        0,
        3
      )}) ${lsData.phone_primary.slice(3, 6)} ${lsData.phone_primary.slice(6)}`;
      setPhoneNumber(displayNumber);
    }
  }, [lsData.phone_primary]);

  const today = new Date();
  const solarCakePostData = lsData
    ? {
        ParentUuid: lsData.uuid,
        UUID: newUUID,
        UniversalLeadId: uuidPost(surveyType, lsData, leadId),
        CkmSubId1: offerId,
        CkmSubId2: newUUID,
        CkmSubId3: `${vwoVariation} | ${typVwoVariation}`,
        CkmSubId4: affiliateId(surveyType),
        CkmSubId5: lsData.site_name,
        CkmOfferId: '2376',
        Product: 'Home Improvement',
        ProjectType: 'Solar',
        ProjectTimeFrame: 'Timing Flexible',
        Residential: 'Yes',
        HomeOwner: 'Yes',
        ElectricBill: '151-200',
        FirstName: lsData.first_name,
        LastName: lsData.last_name,
        Address: lsData.address,
        City: lsData.city,
        State: lsData.state,
        ZipCode: lsData.zipcode,
        Email: lsData.email,
        PhoneNumber: lsData.phone_primary,
        PhoneSecondary: lsData.phone_primary,
        HasSolar: '0',
        ConsentLang:
          '<div class="privacy_rights well" id="srDisclosure"><input id="leadid_tcpa_disclosure" type="hidden" /> <label for="leadid_tcpa_disclosure">By clicking the button and submitting a form, you are providing your electronic signature in which you consent, acknowledge and agree to the following: (a) our <a href="/rmp-tos.html" target="_blank">Terms of Service</a> and <a href="/rmp-privacy.html" target="_blank">Privacy Policy</a>, including our arbitration provision. (b) You are providing express written consent to share your information with RateMarketplace, and up to <a href="/ServicePartners.html" target="_blank">4 home service and solar professionals</a> or authorized third parties calling on our, or their behalf who may contact you about their products and services. (c) You give consent (consent not required as a condition to purchase a good/service) for them/us to contact you through automated means, for marketing purposes at the phone number(s) and at the email address you have provided (e.g. automatic telephone dialing system, text, artificial or pre-recorded messaging) via telephone, mobile device (including SMS and MMS) and/or email, even if your telephone number is currently listed on any state, federal or corporate Do Not Call registry. You may opt-out from SMS or Text messages at any time by replying STOP. Data and Msg rates may apply. As an alternative, you may contact us by email at: <a href="mailto:customercare@ratemarketplace.com">customercare@ratemarketplace.com</a>. You understand that you may revoke this consent at any time.</label></div>',
        IPAddress: lsData.user_ip,
        UserAgent: lsData.user_agent,
        SiteName: 'qualifiedsolarsurvey.com',
        EventDate: today.toISOString(),
        CkmAffiliateId: affiliateId(surveyType),
        xxTrustedFormToken: lsData.xxTrustedFormToken,
        TrustedFormCertUrl: lsData.xxTrustedFormCertUrl,
        TcpaType: 'Solar_d',
        LoanPurpose: null,
        Project: 'Solar',
        TyPageShown: 'GCR USR',
        Vertical: 'home_services',
        SiteVertical: 'hs_solar',
        SubVerticalName: 'Solar',
        CkmCampaignId: campaignId(surveyType),
        CkmKey: campaignKey(surveyType),
        SiteServer: 'sc-frontend-v2',
        MapName: 'FrontEndSignup',
        CakeRequestIdUrl: clickPixelFire(surveyType, lsData, offerId, newUUID, vwoVariation, typVwoVariation) // prettier-ignore
      }
    : {};

  const handleSolarYesNo = value => {
    if (value === 0) {
      webHookTrack('upsell', {
        No_Thanks: true,
        Ty_Page_Shown: `${newUUID} - ${lsData.TyPageShown}`,
      });

      g4EventTracking('upsell', {
        No_Thanks: true,
        Ty_Page_Shown: `${newUUID} - ${lsData.TyPageShown}`,
      });

      setHideForm(true);
    }
  };

  const finalSubmit = values => {
    const badCredit = ['fair', 'poor'];
    const token = document.getElementById('xxTrustedFormToken_0').value;
    const certUrl = document.getElementById('xxTrustedFormCertUrl_0').value;
    // update main solar cake post data with five fields from form
    solarCakePostData.Shade = values.Shade;
    solarCakePostData.ElectricCompany = values.ElectricCompany;
    solarCakePostData.CreditRating = handleCredit(surveyType, values, lsData);
    solarCakePostData.PageId = values.___pageid___;
    solarCakePostData.xxTrustedFormToken = token;
    solarCakePostData.TrustedFormCertUrl = certUrl;

    // update these values for the upsell phone test
    if (surveyType !== 'homeservices') {
      solarCakePostData.PhoneNumber = values.PhoneNumber.replace(/\D+/g, '');
      solarCakePostData.PhoneSecondary = values.PhoneNumber.replace(/\D+/g, '');
    }

    setTyOverlay(true);
    setFormComplete(true);

    const successFunction = data => {
      if (data) {
        console.log(data);
      }
    };
    setTimeout(() => {
      setHideForm(true);
    }, 2000);

    const postToCakeAPI = `https://external${envToggle}.printfinger.tech:7113/api/BuyerPreQualification`;

    const submitData = {
      BuyerName: 'Home Services',
      MapName: 'FrontEndSignup',
      Uuid: newUUID,
      Payload: solarCakePostData,
    };

    // send information through API
    sendRequest(submitData, postToCakeAPI, successFunction);
  };

  const formSubmit = (values, action) => {
    webHookTrack('upsell', {
      Solar_Upsell_Submit: true,
      Solar_Upsell_UUID: newUUID,
      Ty_Page_Shown: `${newUUID} - ${lsData.TyPageShown}`,
    });

    g4EventTracking('upsell', {
      Solar_Upsell_Submit: true,
      Solar_Upsell_UUID: newUUID,
      Ty_Page_Shown: `${newUUID} - ${lsData.TyPageShown}`,
    });

    action.setSubmitting(false);
    return finalSubmit(values);
  };

  return (
    <ModalContainer secondary={hideForm}>
      {!hideForm && (
        <Solar>
          <UpsellLogo />
          <div id="solarInit">
            <UpsellHeader
              lsData={lsData}
              surveyType={surveyType}
              handleSolarYesNo={handleSolarYesNo}
            />

            {tyOverlay && (
              <SolarTyOverlay data-testid="solar-form-submission">
                <h2>Thank you!</h2>
                <p>
                  Someone will contact you soon regarding solar power options!
                </p>
              </SolarTyOverlay>
            )}
            {!formComplete && (
              <Formik
                enableReinitialize
                initialValues={{
                  Shade: 'No Shade',
                  credit_rating: '',
                  ElectricCompany: '',
                  universal_leadid: '',
                  PhoneNumber: '',
                  ___pageid___: '',
                }}
                onSubmit={formSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({
                  handleSubmit,
                  values,
                  errors,
                  setFieldValue,
                  setErrors,
                }) => (
                  <form onSubmit={handleSubmit} id="solarForm">
                    <div className="solar-questions">
                      <UpsellShadeInput config={config} />
                      {config.upsellProviderButton_test ? (
                        <UpsellProviderButtons
                          values={values}
                          isRequired={isRequired}
                          errors={errors}
                          setElectricCompany={setElectricCompany}
                          electricCompany={electricCompany}
                          lsData={lsData}
                          setNoLocalStorage={setNoLocalStorage}
                          envToggle={envToggle}
                          setFieldValue={setFieldValue}
                          config={config}
                          setErrors={setErrors}
                        />
                      ) : (
                        <UpsellElectricProvider
                          values={values}
                          isRequired={isRequired}
                          errors={errors}
                          setElectricCompany={setElectricCompany}
                          electricCompany={electricCompany}
                          lsData={lsData}
                          setNoLocalStorage={setNoLocalStorage}
                          envToggle={envToggle}
                          config={config}
                        />
                      )}
                      {surveyType !== 'homeservices' ? (
                        <UpsellPhoneInput
                          config={config}
                          setFieldValue={setFieldValue}
                          values={values}
                          errors={errors}
                        />
                      ) : (
                        <UpsellCreditInput
                          values={values}
                          isRequired={isRequired}
                          setErrors={setErrors}
                          errors={errors}
                          config={config}
                        />
                      )}
                    </div>
                    {surveyType !== 'homeservices' && (
                      <UpsellTcpa upsellType="homeservices" />
                    )}
                    <div className="btn-container">
                      <button
                        type="submit"
                        className="submit"
                        id="solar-check"
                        data-testid="solar-form-submit"
                      >
                        {submitText(surveyType)}
                      </button>
                    </div>
                    {surveyType !== 'homeservices' && (
                      <UpsellDisclosure config={config} params={params} />
                    )}
                  </form>
                )}
              </Formik>
            )}
          </div>
        </Solar>
      )}
    </ModalContainer>
  );
};

export default SolarTYForm;
