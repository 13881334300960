const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, cc => {
    const r = Math.floor(Math.random() * 16 || 0);
    const v = cc === 'x' ? r : (r && 0x3) || 0x8;
    return v.toString(16);
  });
};

const affiliateId = survey => {
  let id = '';
  switch (survey) {
    case 'homeservices':
      id = 1767;
      break;
    case 'va':
    case 'harp':
    case 'fha':
      id = 1907;
      break;
    default:
      id = 1767;
  }
  return id;
};
const campaignId = survey => {
  let id = '';
  switch (survey) {
    case 'homeservices':
      id = 30025;
      break;
    case 'va':
    case 'harp':
    case 'fha':
      id = 35236;
      break;
    default:
      id = 30025;
  }
  return id;
};

const campaignKey = survey => {
  let key = '';
  switch (survey) {
    case 'homeservices':
      key = '1tRpWXTcfKSDgiajccYZA';
      break;
    case 'va':
    case 'harp':
    case 'fha':
      key = 'pGaEA3SrsDqDgiajccYZA';
      break;
    default:
      key = '1tRpWXTcfKSDgiajccYZA';
  }
  return key;
};

const clickPixelFire = (
  survey,
  data,
  offerId,
  newUUID,
  vwoVariation,
  typVwoVariation
) => {
  let pixel = '';
  switch (survey) {
    case 'homeservices':
      pixel =
        'https://suited45trk.com/?oex3=5updvBQM31qqPDrbXhGoRDG7Dj%2FrHPqx&cp=js&s1=';
      break;
    case 'va':
    case 'harp':
    case 'fha':
      pixel =
        'https://suited45trk.com/?f5c=1RJPP4ZszUu0y3QM21fKAzHyoULgxPWH&cp=js&s1=';
      break;
    default:
      pixel =
        'https://suited45trk.com/?oex3=5updvBQM31qqPDrbXhGoRDG7Dj%2FrHPqx&cp=js&s1=';
  }
  return `${pixel}${offerId}&s2=${newUUID}&s3=${vwoVariation}|${typVwoVariation}&s4=${affiliateId(
    survey
  )}&s5=${data.site_name}`;
};

const impressionPixelFire = (
  survey,
  data,
  offerId,
  newUUID,
  vwoVariation,
  typVwoVariation
) => {
  let pixel = '';
  switch (survey) {
    case 'homeservices':
      pixel =
        'https://suited45trk.com/i.ashx?E=FBQj9kSSCKDB8BNtJEI%2FUzHyoULgxPWH&s1=';
      break;
    case 'va':
    case 'harp':
    case 'fha':
      pixel =
        'https://suited45trk.com/i.ashx?E=XVxFrbIJtscJU78lLyZwkzG7Dj%2frHPqx&s1=';
      break;
    default:
      pixel =
        'https://suited45trk.com/i.ashx?E=FBQj9kSSCKDB8BNtJEI%2FUzHyoULgxPWH&s1=';
  }
  return `${pixel}${offerId}&s2=${newUUID}&s3=${vwoVariation}|${typVwoVariation}&s4=${affiliateId(
    survey
  )}&s5=${data.site_name}`;
};

const uuidPost = (survey, data, lead) => {
  let uuid = '';
  switch (survey) {
    case 'homeservices':
      uuid = data.universal_leadid;
      break;
    case 'va':
    case 'harp':
    case 'fha':
      uuid = lead;
      break;
    default:
      uuid = data.universal_leadid;
  }
  return uuid;
};

const submitText = survey => {
  let text = '';
  switch (survey) {
    case 'homeservices':
      text = 'Show me my Solar and Roofing Results!';
      break;
    case 'va':
    case 'harp':
    case 'fha':
      text = 'Show me my Solar and Lending Results!';
      break;
    default:
      text = 'Show me my Solar and Roofing Results!';
  }
  return text;
};

const handleCredit = (survey, values, data) => {
  const badCredit = ['fair', 'poor'];
  let credit = '';
  switch (survey) {
    case 'homeservices':
      credit = values.credit_rating;
      break;
    case 'va':
    case 'harp':
    case 'fha':
      credit = data.credit_rating;
      break;
    default:
      credit = values.credit_rating;
  }
  return credit;
};

const jornayaReRun = setLeadId => {
  const { LeadiD } = window;
  try {
    if (typeof LeadiD !== 'undefined') {
      LeadiD.reInit();
      setTimeout(() => {
        setLeadId(LeadiD.token);
      }, 200);
    } else {
      const s = document.createElement('script');
      s.id = 'LeadiDscript_campaign';
      s.type = 'text/javascript';
      s.async = true;
      s.src =
        '//create.lidstatic.com/campaign/f21bcfe7-421d-0b59-65fe-3d36e8d9c8d6.js?snippet_version=2&f=reset&callback=reloadLeadId';
      document.body.appendChild(s);

      LeadiD.reInit();

      setTimeout(() => {
        setLeadId(LeadiD.token);
      }, 200);
    }
  } catch (error) {
    console.log(error);
  }
};

// Taken from formik github example.
const isRequired = message => value => (value ? undefined : message);

export {
  jornayaReRun,
  isRequired,
  submitText,
  handleCredit,
  uuidPost,
  campaignKey,
  campaignId,
  affiliateId,
  generateUUID,
  impressionPixelFire,
  clickPixelFire,
};
